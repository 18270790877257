import React from 'react';
import { TopSection, AboutMe, Experiences } from 'components';
import { Layout, SEO } from 'components';

const Home = () => {
  return (
    <Layout>
      <TopSection />
      <AboutMe />
      <Experiences />
      {/* <IntroSection /> */}
      {/*<ArticlesSection /> */}
    </Layout>
  );
};

export const Head = () => {
  return (
    <SEO
      title="Home"
      description="Homepage - Meet me and take a peek at my articles!"
    />
  );
};

export default Home;
